import { streamInitPromise } from 'components/permissionWorkflow/utils';
import EventBridge from 'event-bridge';
import camelCase from 'lodash/camelCase';
import { isChrome, isChromeBrowser, isSafari } from 'utils/browserCheck';
import { Events } from 'utils/constants/containers/airmeet';
import {
    getCustomMediaUserId,
    getScreenShareUserId,
    isCustomMediaStream,
    isScreenShareStream,
} from 'utils/constants/live-airmeet';
import { LOG_LEVEL } from 'utils/constants/logger';
import {
    broadcastLogConstants,
    QOELogger,
    QOEUserDropLogger,
    userDropCases,
} from 'utils/constants/qoeLogConstants';
import { STAGE_STREAM_MODES } from 'utils/constants/stageStreamModes';
import { getBroadcastFilter, isFilterSupported } from 'utils/FilterManager';
import FilterStorageManager from 'utils/FilterStorageManager';
import { logger } from 'utils/logger';
import { filterLogs, stageFilterLogger } from 'utils/loggers/filterLogger';
import { v4 as uuidv4 } from 'uuid';
import AirmeetRTCClient, { getDevicesForStream } from './AirmeetRTCClient';
import resolutionsList, {
    RESOLUTIONS_SD,
    VIDEO_MUTE_PROFILE,
} from './StageResolutionsList';

const localLog = logger.init('RTCBroadcastClient:', 'green', LOG_LEVEL.INFO);
const errorLog = logger.init(
    'RTCBroadcastClient Error:',
    'red',
    LOG_LEVEL.INFO
);
const traceLog = (message, props = {}) => {
    logger.info(`RTCBroadcastClient::TRACE::${message}`, props);
};

const mobileTranscodingLog = logger.init('Mobile Transcoding', 'blue');
const AV_CUSTOM_FIX = broadcastLogConstants.BROADCAST_AV_CUSTOM_FIX;

export default class AirmeetRTCClientBroadcast extends AirmeetRTCClient {
    constructor(accountUid, appId, params) {
        super(accountUid, appId, params);
        this.setResolution(params.resolution);

        this.mainStream = '';
        this.getStreamById = this.getStreamById.bind(this);
        this.remoteStreams = {};
        this.enableSubscribing = false;
        this.mode = STAGE_STREAM_MODES.highResolution;
        this.streamPublished = false;
        this.isJoiningChannelInProgress = false;
        this.disallowStreamsForSubscribing = {};
        this.streamReconnectTimer = {};
        this.localStreamsReconnecting = [];
        this.hasRemoteStreamAudioMute = false;
        this.filterInfo = null;
        this.filterManager = null;
        this.isFilterSupported = false;
        this.cameraOffOnMute = false;
    }

    initializeFilterManager() {
        this.filterManager = getBroadcastFilter();
        this.isFilterSupported = isFilterSupported();
    }

    async closeFilterManager() {
        try {
            await this.filterManager.close();
        } catch (e) {
            logger.error(
                'Error occured while closing filter manager of Broadcast client',
                e
            );
        }
    }

    setCanUseFilter(isEnabled) {
        if (isEnabled && !this.filterManager) {
            this.initializeFilterManager();
        } else if (!isEnabled && this.filterManager) {
            this.closeFilterManager();
        }
        this.canUseFilter = isEnabled;
    }

    addDisallowStreamsForSubscribing(uids = {}) {
        this.disallowStreamsForSubscribing = {
            ...this.disallowStreamsForSubscribing,
            ...uids,
        };
        localLog("Added uid's in disallowStreamsForSubscribing:", uids);
        Object.keys(uids).forEach((uid) => {
            //const stream = this.getStream(uid);
            //if (stream && !stream.local) {
            this.unsubscribeStream(uid);
            // }
        });
    }

    setResolution(resolution) {
        this.resolution = resolution || RESOLUTIONS_SD;
        this.resolutionsInfo =
            resolutionsList[this.resolution] ||
            this.resolutionsList[RESOLUTIONS_SD];
    }

    getStreamById(id) {
        const streamList = this.streams;
        return streamList.filter((item) => {
            return item.getId() === id;
        })[0];
    }

    getGatewayClient() {
        return this.client?.gatewayClient;
    }

    bindEvents() {
        const { client, mode } = this;
        const self = this;
        // For Share screen client not need to subscribe to events
        if (this.isShareScreenAccount || this.isCustomMediaAccount) {
            return;
        }

        client.on('stream-reconnect-end', (evt) => {
            if (
                Object.keys(client.gatewayClient.localStreams).includes(
                    evt.uid.toString()
                ) &&
                evt.success
            ) {
                let index = this.localStreamsReconnecting.indexOf(evt.uid);
                if (index > -1) {
                    this.localStreamsReconnecting.splice(index, 1);
                }
            }
            if (!evt.success) {
                QOEUserDropLogger(
                    userDropCases.AGORA_GAVE_UP_ON_RECOVERABILITY.label,
                    evt.reason
                );
            }
            localLog(
                `${AV_CUSTOM_FIX} stream-reconnect-end`,
                evt.uid,
                evt.success,
                evt.reason
            );

            // if (
            //     evt.success ||
            //     !['REQUEST_ABORT', 'INVALID_LOCAL_STREAM'].includes(evt.reason)
            // ) {
            //     !evt.success &&
            //         errorLog(
            //             'stream-reconnect-end: failed',
            //             evt.uid,
            //             evt.success,
            //             evt.reason
            //         );
            //     return;
            // }

            if (
                !evt.success &&
                evt.uid === this.accountUid &&
                evt.reason == 'INVALID_LOCAL_STREAM' &&
                client.highStreamState === 1
            ) {
                // Reset the stream state
                client.highStreamState = 2;
                errorLog(
                    'stream-reconnect-end: reset the highStreamState to 2',
                    evt.uid,
                    evt.success,
                    evt.reason
                );
                return;
            }

            if (!evt.success) {
                errorLog(
                    `${AV_CUSTOM_FIX} stream-reconnect-end: failed`,
                    evt.uid,
                    evt.success,
                    evt.reason
                );

                errorLog(
                    `${AV_CUSTOM_FIX} pc connection status for the failed reconnect for remote streamID ${evt.uid}`,
                    this.getGatewayClient().remoteStreams[evt.uid]?.pc
                        ?.peerConnection?.connectionState
                );
            }

            if (evt.uid && (evt.success || evt.reason === 'REQUEST_ABORT')) {
                this.deleteStreamReconnectTimer(evt.uid);
            }
        });

        client.on('stream-reconnect-start', (evt) => {
            if (
                Object.keys(client.gatewayClient.localStreams).includes(
                    evt.uid.toString()
                ) &&
                !this.localStreamsReconnecting.includes(evt.uid)
            ) {
                this.localStreamsReconnecting.push(evt.uid);
            }
            localLog(
                `${AV_CUSTOM_FIX} stream-reconnect-start`,
                evt.uid,
                evt.success,
                evt.reason,
                evt
            );
            if (
                evt.uid === this.accountUid &&
                client.highStreamState === 0 &&
                !this.localStream &&
                client.highStream
            ) {
                // Reset the stream state
                client.unpublish(client.highStream);
                localLog(
                    'stream-reconnect-start: unpublish stream',
                    evt,
                    client.highStreamState,
                    !!this.localStream
                );

                return;
            }

            // this.emit('stream-subscribe-failed', {
            //     success: false,
            //     err: evt.reason,
            //     streamId: evt.uid,
            // });

            if (evt.uid) this.setStreamReconnectTimer(evt.uid);
        });

        // Enable report the active remote users who are speaking and their volume regularly.
        // If this method is enabled, the SDK triggers the "volume-indicator" callback to report
        // the volumes every two seconds, regardless of whether there are active speakers.

        client.enableAudioVolumeIndicator();

        // subscribeStreamEvents
        client.on('stream-added', function (evt) {
            const stream = evt.stream;
            const id = stream.getId();
            localLog(
                `New stream added:  ${id}`,
                new Date().toLocaleTimeString()
            );

            if (self.disallowStreamsForSubscribing[id]) {
                return localLog(`Skip to subscribing the stream: ${id}`);
            }
            if (
                isScreenShareStream(id) &&
                getScreenShareUserId(id) === self.accountUid
            ) {
                QOELogger(
                    broadcastLogConstants.BROADCAST_PUBLISHED_STREAM_SUCCESSFULLY,
                    stream.getId()
                );
                localLog(
                    'Screen share local stream successfully: ' + stream.getId()
                );

                self.emit(Events.streamAddedScreen, {
                    userId: getScreenShareUserId(id),
                    stream,
                    isOwner: true,
                });
                return false;
            }
            if (
                isCustomMediaStream(id) &&
                getCustomMediaUserId(id) === self.accountUid
            ) {
                localLog(
                    'custom media local stream successfully: ' + stream.getId()
                );
                self.emit(Events.streamAddedCustomMedia, {
                    userId: getCustomMediaUserId(id),
                    stream,
                    isOwner: true,
                });
                return false;
            }
            self.remoteStreams[id] = {
                stream,
                audioFirstFrameDecoded: false,
                videoFirstFrameDecoded: false,
            };
            //On stream add event set the mute as false
            self.remoteMuteVideos[id] = false;
            self.remoteMuteAudios[id] = false;
            self.emit('remote-stream-added', { id, stream });
            if (!self.enableSubscribing) {
                return;
            }
            QOELogger(
                broadcastLogConstants.BROADCAST_ATTEMPTING_TO_SUBSCRIBE_TO_REMOTE_STREAM,
                self.accountUid,
                `remote stream id ${stream.getId()}`
            );
            //localLog(`Subscribe ${id}`, stream);
            self.setStreamReconnectTimer(stream.getId(), true);
            self.subscribeStream(stream);
        });

        client.on('peer-leave', function (evt) {
            const id = evt.uid;
            localLog('Peer has left: ' + id + new Date().toLocaleTimeString());
            self.removeStream(evt.uid);
        });

        //DEPRECATED from 3.0.2. `active-speaker`
        // let activeSpeaker = 0;
        // client.on('active-speaker', function (evt) {
        //     let id = parseInt(evt.uid);
        //     if (id && self.activeSpeaker !== id) {
        //         activeSpeaker = id;
        //         setTimeout(function () {
        //             if (activeSpeaker === id) {
        //                 self.activeSpeaker = activeSpeaker;
        //                 //const mainId = self.mainStream ? self.mainStream.getId() : null;
        //                 //self.setHighStream(mainId, id);
        //             }
        //         }, 2000);
        //     }
        // });

        client.on('stream-subscribed', function (evt) {
            const { stream } = evt;
            let sId = stream.getId();

            // client.setStreamFallbackOption(stream, 2);
            QOELogger(
                broadcastLogConstants.BROADCAST_SUBSCRIBED_TO_REMOTE_STREAM_SUCCESSFULLY,
                self.accountUid,
                `remote stream id ${sId}`
            );

            localLog('Subscribe remote stream successfully: ' + stream.getId());
            if (!self.remoteStreams[sId]) {
                self.remoteStreams[sId] = {
                    stream,
                    audioFirstFrameDecoded: false,
                    videoFirstFrameDecoded: false,
                };
            }
            self.deleteStreamReconnectTimer(sId, true);

            if (isScreenShareStream(sId)) {
                self.emit(Events.streamAddedScreen, {
                    userId: getScreenShareUserId(sId),
                    stream,
                });
            }
            if (isCustomMediaStream(sId)) {
                self.emit(Events.streamAddedCustomMedia, {
                    userId: getCustomMediaUserId(sId),
                    stream,
                });
            }
            if (
                isScreenShareStream(sId) &&
                getScreenShareUserId(sId) === self.accountUid
            ) {
                return false;
            }

            if (
                isCustomMediaStream(sId) &&
                getCustomMediaUserId(sId) === self.accountUid
            ) {
                return false;
            }

            self.addStream(stream);

            if (
                isScreenShareStream(stream.getId()) ||
                isCustomMediaStream(stream.getId())
            ) {
                //Under poor network conditions, the SDK can choose to subscribe to the low-video stream.
                client.setStreamFallbackOption(stream, 1);
                self.setHighStream(null, stream.getId());
            } else {
                //Under poor network conditions, the SDK can choose to subscribe to the low-video stream or only the audio stream.
                client.setStreamFallbackOption(stream, 2);
            }

            // else if (
            //     self.mainStream &&
            //     self.mainStream.getId() !== stream.getId()
            // ) {
            //     // client.setRemoteVideoStreamType(stream, 1);
            // }
            if (self.mode === STAGE_STREAM_MODES.lowResolution) {
                client.setRemoteVideoStreamType(stream, 1);
            }

            // const durations = 2;
            // setTimeout(() => {
            //     if (self.getStream(sId)) {
            //         stream.getStats((stats) => {
            //             const streamLogs = [];
            //             streamLogs.push(`Stream Id: ${stream.getId()}`);
            //             streamLogs.push(
            //                 `Remote Stream audioReceiveBytes: ${stats.audioReceiveBytes}`
            //             );
            //             streamLogs.push(
            //                 `Remote Stream audioReceivePackets: ${stats.audioReceivePackets}`
            //             );
            //             streamLogs.push(
            //                 `Remote Stream videoReceiveBytes: ${stats.videoReceiveBytes}`
            //             );
            //             streamLogs.push(
            //                 `Remote Stream videoReceivePackets: ${stats.videoReceivePackets}`
            //             );
            //             if (
            //                 (!stats.audioReceiveBytes &&
            //                     !stats.audioReceivePackets &&
            //                     !stats.videoReceiveBytes &&
            //                     !stats.videoReceivePackets) ||
            //                 (stats.audioReceiveBytes == 0 &&
            //                     stats.audioReceivePackets == 0 &&
            //                     stats.videoReceiveBytes == 0 &&
            //                     !stats.videoReceivePackets == 0)
            //             ) {
            //                 self.enableProxyServer().catch(() => {});
            //             }
            //             console.log(streamLogs);
            //         });
            //     }
            // }, durations * 1000);
        });

        client.on('first-video-frame-decode', function (evt) {
            const stream = evt.stream;
            const id = stream.getId();
            localLog(`first-video-frame-decode  for stream: ${id}`);
            if (self.remoteStreams[id]) {
                self.remoteStreams[id]['videoFirstFrameDecoded'] = true;
            }
            self.emit(Events.remoteStreamFirstVideoFrameDecode, {
                id,
                stream,
            });
        });

        client.on('first-audio-frame-decode', function (evt) {
            const stream = evt.stream;
            const id = stream.getId();
            localLog(`first-audio-frame-decode for stream: ${id}`);
            if (self.remoteStreams[id]) {
                self.remoteStreams[id]['audioFirstFrameDecoded'] = true;
            }
            self.emit(Events.remoteStreamFirstAudioFrameDecode, {
                id,
                stream,
            });
        });

        client.on('network-type-changed', function (evt) {
            localLog(`Network Type Changed to ${evt.networkType}`);
        });

        client.on('stream-published', (evt) => {
            /*client.enableDualStream(
        function() {
          console.log('Enable dual stream success!');
        },
        function(err) {
          console.log(err);
        }
      );*/
            if (self.localStream) {
                self.streamPublished = true;
            }
            if (!this.isEnableLocalAudioMonitor)
                self.emit('stream-published', self.localStream);
        });

        client.on('stream-fallback', function (evt) {
            const { attr, uid } = evt;
            localLog('stream-fallback', { attr, uid });
            if (attr === 1) {
                // the remote media stream falls back to audio-only due to unreliable network conditions.
                self.remoteMuteVideos[uid] = true;
                self.emit('onMuteVideo', { uid });
            }
            if (attr === 0) {
                // the remote media stream switches back to the video stream after the network conditions improve.
                self.remoteMuteVideos[uid] = false;
                self.emit(Events.onUnmuteVideo, { uid });
            }
        });

        const removeStream = (stream, removePermanently, offline) => {
            const id = stream.getId();
            localLog('Stream removed: ' + id);
            if (isScreenShareStream(stream.getId())) {
                self.emit(Events.streamAddedScreen, {});
            }

            if (isCustomMediaStream(stream.getId())) {
                self.emit(Events.streamAddedCustomMedia, {});
            }

            self.removeStream(stream.getId(), removePermanently, offline);
        };
        client.on('stream-removed', (evt) => {
            const { stream } = evt;
            localLog('stream-removed:', { uid: stream.getId() });
            removeStream(stream, true, !!evt.uid);
        });

        client.on('subP2PLost', (evt) => {
            const { stream } = evt;
            localLog('subP2PLost:', { uid: stream.getId() });
            // Not wait till unsubscribe the stream, as got lost p2p remove form streams
            removeStream(stream, true, true);
            this.emit(`stream-subP2PLost`, {
                uid: stream.getId(),
            });
        });

        ['mute-video', 'unmute-video'].forEach((eventName) => {
            const isMute = eventName === 'mute-video';
            client.on(eventName, function (evt) {
                const propName = camelCase(`on-${eventName}`);
                self.remoteMuteVideos[evt.uid] = isMute;
                self.emit(propName, evt);
                localLog(`${propName} ${evt.uid}`);
                self.emit(propName, evt);
            });
        });

        ['mute-audio', 'unmute-audio'].forEach((eventName) => {
            const isMute = eventName === 'mute-audio';
            client.on(eventName, function (evt) {
                const propName = camelCase(`on-${eventName}`);
                self.remoteMuteAudios[evt.uid] = isMute;
                self.emit(propName, evt);
            });
        });

        client.on('network-quality', function (stats) {
            const oldQuality = {
                downlinkNetworkQuality: self.downlinkNetworkQuality,
                uplinkNetworkQuality: self.uplinkNetworkQuality,
            };
            self.pushNetworkStats(stats);

            const downlinkAvg = self.getNetworkQualityAvg(
                self.networkStats.downlinkNetworkQuality
            );
            const uplinkAvg = self.getNetworkQualityAvg(
                self.networkStats.uplinkNetworkQuality
            );

            self.downlinkNetworkQuality = downlinkAvg;
            self.uplinkNetworkQuality = uplinkAvg;
            const quality = {
                downlinkNetworkQuality: self.downlinkNetworkQuality,
                uplinkNetworkQuality: self.uplinkNetworkQuality,
            };

            if (
                oldQuality.downlinkNetworkQuality !==
                    quality.downlinkNetworkQuality ||
                oldQuality.uplinkNetworkQuality !== quality.uplinkNetworkQuality
            ) {
                localLog(
                    `network-quality-updated`,
                    quality,
                    self.networkStats,
                    stats.downlinkNetworkQuality,
                    stats.uplinkNetworkQuality
                );
            }
            self.emit('network-quality-updated', {
                uid: self.accountUid,
                quality,
            });
            self.logPingPongTimer();
        });

        // Handle Channel Rejoin case
        client.on('rejoin', (data) => {
            if (
                this.localStream &&
                Object.keys(client.gatewayClient.localStreams).length === 0
            ) {
                localLog('Publishing with re-join case');
                self.publish();
            } else if (
                client.highStreamState === 0 &&
                !this.localStream &&
                client.highStream
            ) {
                // unpublish the stream from SDK level due to not have active local stream
                client.unpublish(client.highStream);
                localLog('Unpublish stream with re-join case');
            }
        });

        // Agora event log to investigate the issue
        client.on('error', function (err) {
            errorLog('Got agora error msg:', err);
            if (err.type === 'error' && err.reason === 'REQUEST_ABORT') {
                this.requestAbortError = true;
            }
        });

        this.infoDetectSchedule();

        window.addEventListener('error', this.sdpErrorHandle.bind(this));
    }

    setStreamReconnectTimer(streamId, freshSubscription = false) {
        if (!streamId) return;

        if (this.streamReconnectTimer[streamId]) {
            this.deleteStreamReconnectTimer(streamId);
        }

        if (
            !this.streamReconnectTimer[streamId] &&
            streamId &&
            !window.disableManualSubscripton
        ) {
            localLog(`${AV_CUSTOM_FIX} set timer for ${streamId}`);

            this.streamReconnectTimer[streamId] = setTimeout(() => {
                let pcConnectionState = this.getGatewayClient().remoteStreams[
                    streamId
                ]?.pc?.peerConnection?.connectionState;

                var streamData = this.getGatewayClient().remoteStreams[streamId]
                    ?.stream;

                if (!streamData || pcConnectionState === 'closed') {
                    localLog(
                        `${AV_CUSTOM_FIX} stream status for stream ${streamId} stream ${streamData} pc state ${pcConnectionState}`
                    );
                }

                let stream = this.getGatewayClient()?.remoteStreams[streamId];

                // only for adding log, don't try subscription
                if (freshSubscription) {
                    errorLog(
                        `${AV_CUSTOM_FIX} couldn't receive stream subscription ${streamId}`
                    );
                }

                if (
                    stream &&
                    (pcConnectionState === 'closed' || freshSubscription)
                ) {
                    localLog(
                        `${AV_CUSTOM_FIX} stream status in the channel ${streamId} - ${this.getGatewayClient().remoteStreamsInChannel.has(
                            streamId
                        )}`
                    );
                    this.getGatewayClient().subscribe(
                        stream,
                        () => {
                            localLog(
                                `${AV_CUSTOM_FIX} subscription success ${streamId}`
                            );
                        },
                        (e) => {
                            errorLog(
                                `${AV_CUSTOM_FIX} subscription failed ${
                                    freshSubscription
                                        ? 'first-time-sub'
                                        : 'reconnect-sub'
                                } ${streamId}, ${e}`
                            );
                        }
                    );
                    // this.subscribeStream(stream);
                    errorLog(
                        `${AV_CUSTOM_FIX} try re subscription ${
                            freshSubscription
                                ? 'first-time-sub'
                                : 'reconnect-sub'
                        } - ${streamId}`
                    );
                }
                delete this.streamReconnectTimer[streamId];
            }, 20000);
        }
    }

    deleteStreamReconnectTimer(streamId, isFirstTime = false) {
        if (!streamId && this.streamReconnectTimer[streamId]) return;

        localLog(
            `${AV_CUSTOM_FIX} ${
                isFirstTime ? ' -fresh subscription' : ''
            } clear timer for `,
            streamId
        );
        clearTimeout(this.streamReconnectTimer[streamId]);
        delete this.streamReconnectTimer[streamId];
    }

    deleteAllTimer() {
        Object.keys(this.streamReconnectTimer || {}).forEach((streamId) =>
            this.deleteStreamReconnectTimer(streamId)
        );
    }

    pushNetworkStats(stats = {}) {
        const movingAvgCount = 5;
        if (stats.downlinkNetworkQuality != undefined)
            this.networkStats.downlinkNetworkQuality.push(
                stats.downlinkNetworkQuality
            );
        if (stats.uplinkNetworkQuality != undefined)
            this.networkStats.uplinkNetworkQuality.push(
                stats.uplinkNetworkQuality
            );

        const downlinkStatLength = this.networkStats.downlinkNetworkQuality
            .length;
        if (downlinkStatLength > movingAvgCount)
            this.networkStats.downlinkNetworkQuality = this.networkStats.downlinkNetworkQuality.slice(
                Math.max(downlinkStatLength - movingAvgCount, 0)
            );

        const uplinkStatLength = this.networkStats.uplinkNetworkQuality.length;
        if (uplinkStatLength > movingAvgCount)
            this.networkStats.uplinkNetworkQuality = this.networkStats.uplinkNetworkQuality.slice(
                Math.max(uplinkStatLength - movingAvgCount, 0)
            );
    }

    getNetworkQualityAvg(stats = []) {
        const arrSum = (accu, val) => accu + val;
        const average = stats.reduce(arrSum, 0) / stats.length;
        return Math.round(average);
    }

    sdpErrorHandle(error) {
        if (error.message.includes("Cannot read property 'sdp' of null")) {
            localLog('sdp error, retying stream subscription');

            setTimeout(() => {
                if (!this.getGatewayClient()?.remoteStreams) return;
                Object.keys(this.getGatewayClient().remoteStreams).forEach(
                    (streamId) => {
                        let pcConnectionState = this.getGatewayClient()
                            .remoteStreams[streamId]?.pc?.peerConnection
                            .connectionState;

                        localLog(
                            `${AV_CUSTOM_FIX} check the pc status ${streamId} state ${pcConnectionState}`
                        );

                        var streamData = this.getGatewayClient().remoteStreams[
                            streamId
                        ].stream;
                        if (!streamData && pcConnectionState === 'closed') {
                            localLog(
                                `${AV_CUSTOM_FIX} shall we re-subscribe ${streamId} stream ${streamData} ${pcConnectionState}`
                            );
                        }
                    }
                );
            }, 5000);
        }
    }

    retryToSubscribeStream(streamUid) {
        return;
        if (!this.client || !this.client.gatewayClient) {
            return;
        }
        if (this.client.gatewayClient.localStreams.hasOwnProperty(streamUid)) {
            return;
        }
        const logPrefix = `retryToSubscribeRemoteStream: ${streamUid}`;
        if (!this.enableSubscribing) {
            localLog(`${logPrefix}: disable subscribe`);
            return;
        }
        if (!this.client.gatewayClient.hasJoined) {
            localLog(`${logPrefix}: User is not joined now`);
            return;
        }

        if (
            !this.client.gatewayClient.remoteStreams.hasOwnProperty(streamUid)
        ) {
            localLog(`${logPrefix}: not found in remote Stream`);
            this.emit('retryToSubscribeRemoteStreamNotFound', {
                streamUid,
            });
            return;
        }
        const remoteStream = this.client.gatewayClient.remoteStreams[streamUid];
        if (remoteStream.stream) {
            localLog(`${logPrefix}: stream has already subscribed.`);
            this.addStream(remoteStream);
            return;
        }
        const subscribeLTS =
            this.client.gatewayClient.remoteStreams[streamUid].subscribeLTS ||
            0;
        if (subscribeLTS >= new Date().getTime() - 2000) {
            localLog(
                `${logPrefix}: stream subscribe request at: ${subscribeLTS}`
            );
            return;
        }

        localLog(`${logPrefix}: request for stream subscribe`);

        this.subscribeStream(
            this.client.gatewayClient.remoteStreams[streamUid]
        );

        // if (!this.client.gatewayClient.remoteStreamsInChannel.has(streamUid)) {
        //     this.emit('retryToSubscribeRemoteStreamNotFound', { streamUid })
        // }
    }

    setEnableSubscribing(enable) {
        if (!this.automationCanSub) {
            if (!this.enableSubscribing) {
                return;
            }
            enable = false;
        }
        const { client, streams, remoteStreams } = this;
        const subscribStreamsId = streams.map((stream) => stream.getId());
        QOELogger(
            broadcastLogConstants.BROADCAST_SET_ENABLE_SUBSCRIBING,
            this.accountUid,
            `has allowed to remote stream subscribing: ${enable}`
        );
        Object.keys(remoteStreams).forEach((streamId) => {
            const { stream } = remoteStreams[streamId];
            if (enable) {
                if (subscribStreamsId.includes(streamId)) return;
                localLog('Subscribing ', streamId);
                this.subscribeStream(stream);
            } else {
                streamId = parseInt(streamId);
                if (!subscribStreamsId.includes(streamId)) return;
                this.unsubscribeStream(streamId, false);
            }
        });

        this.enableSubscribing = enable;
    }

    subscribeStream(stream) {
        if (!this.enableSubscribing) {
            return localLog('Subscribe stream: Disabled');
        }
        if (!stream) {
            errorLog('Stream has not pass');
        }

        if (stream?.local) {
            errorLog('Local Stream not need to subscribe');
        }

        const isVideoEnable =
            isScreenShareStream(stream.getId()) ||
            isCustomMediaStream(stream.getId()) ||
            this.mode !== STAGE_STREAM_MODES.audioOnly;
        const props = {
            video: isVideoEnable,
            audio: true,
        };
        localLog(`Request to subscribe stream: ${stream.getId()}`, props);
        this.client.subscribe(stream, props, (err) => {
            QOELogger(
                broadcastLogConstants.BROADCAST_FAILED_TO_SUBSCRIBE_TO_REMOTE_STREAM,
                this.accountUid,
                `${err} remote stream id ${stream.getId()}`
            );
            errorLog('Failed to subscribe stream', {
                err,
                stream: stream.getId(),
            });
            this.emit('stream-subscribe-failed', {
                success: false,
                err: err,
                streamId: stream.getId(),
            });
        });
    }

    updatePublishVideoProfile(layout, noOfStreams, mainStreamUids) {
        const { localStream, streamPublished } = this;
        if (!layout || !localStream || noOfStreams === 0 || !streamPublished) {
            return;
        }
        this.videoProfileParams = {
            layout,
            noOfStreams,
            mainStreamUids,
        };

        const id = localStream.getId();

        let { videoProfile, layouts: videoProfilesList } = this.resolutionsInfo;
        if (!localStream.isVideoOn()) {
            videoProfile = VIDEO_MUTE_PROFILE;
        } else if (layout === 'split' || layout === 'dominant') {
            const videoProfiles = videoProfilesList['split'];
            const noOfMainStreams = mainStreamUids.length;
            videoProfile = mainStreamUids.includes(id)
                ? videoProfiles[noOfMainStreams]
                    ? videoProfiles[noOfMainStreams]
                    : videoProfiles[6]
                : videoProfiles['smallStream'];
            this.isDominantStream =
                mainStreamUids.includes(id) && layout === 'dominant';
        } else if (layout === 'screen') {
            const videoProfiles = videoProfilesList[layout];
            videoProfile = videoProfiles.smallStream;
            this.isDominantStream = false;
        }

        if (
            localStream.isVideoOn() &&
            this.mode === STAGE_STREAM_MODES.lowResolution &&
            layout !== 'screen'
        ) {
            videoProfile = this.isDominantStream
                ? videoProfilesList.lowDefinitionProfile.mainStream
                : videoProfilesList.lowDefinitionProfile.smallStream;
        }

        if (this.videoProfile === videoProfile) {
            return;
        }

        this.videoProfile = videoProfile;

        const disableDualStream =
            !localStream.isVideoOn() ||
            (!this.isDominantStream && layout !== 'split') ||
            videoProfile.height < 480;

        this.toggleDualStreamMode(disableDualStream);
        traceLog('updatePublishVideoProfile', {
            disableDualStream,
            videoProfile,
        });
        this.scaleResolutionAndBitrate(localStream, videoProfile, (result) => {
            // console.log('ScaleBitrate update', result);
            localLog(
                `Updated the publish stream video profile ${JSON.stringify(
                    videoProfile
                )} for layout ${layout}.`
            );
        });
    }

    scaleResolutionAndBitrate = (localStream, opt, callback = () => {}) => {
        if (
            !this.client.hasPublished ||
            !localStream ||
            !localStream.hasVideo()
        ) {
            return;
        }
        const rtpSendersArray = localStream?.pc?.peerConnection
            ? localStream.pc.peerConnection.getSenders()
            : [];

        if (rtpSendersArray.length) {
            // get video track details
            const rtpVideoSender = rtpSendersArray.find(
                (val) => val.track && val.track.kind === 'video'
            );
            if (!rtpVideoSender) {
                errorLog(
                    `Publish stream video profile error - rtpVideoSender not available: ${localStream}`
                );
                callback({
                    success: false,
                    error: 1001,
                    errDesc: 'NO RTP sender',
                });
                return;
            }
            const params = rtpVideoSender.getParameters();
            // scale down resolution based on users count
            if (!params.encodings) params.encodings = [{}]; // Firefox workaround!
            const orgHeight = rtpVideoSender.track.getSettings().height || 720;
            // [Manu] Race condition where local stream doesn't have proper RTP Sender and Peer connection is not connected
            if (
                params.encodings.length === 0 ||
                typeof params.encodings[0] !== 'object'
            ) {
                errorLog(
                    "In scaleResolutionDownBy, sender doesn't have any encodings"
                );
                return;
            }
            params.encodings[0].scaleResolutionDownBy = Math.max(
                orgHeight / opt.height,
                1
            ); // this will reduce the resolution
            params.encodings[0].maxBitrate = opt.maxBitrate * 1000;
            if (opt.minBitrate) {
                params.encodings[0].minBitrate = opt.minBitrate * 1000;
            }
            rtpVideoSender
                .setParameters(params)
                .then(() => {
                    callback({
                        success: true,
                        errCode: 0,
                        errDesc: '',
                    });
                })
                .catch((e) => {
                    errorLog(
                        `Publish stream video profile error using bitrate and scale ${JSON.stringify(
                            opt
                        )}: ${e}`
                    );
                    callback({
                        success: false,
                        error: 1001,
                        errDesc: e,
                    });
                });
        } else {
            callback({
                success: false,
                error: 1001,
                errDesc: `NO RTP senders Array or Peer Connection does not exist ${
                    localStream ? localStream.getId() : '[localstream is null]'
                }.`,
            });
            return;
        }
    };

    setHighStream(prev, next) {
        const { streams, client } = this;

        const heighStream = streams.find((stream) => stream.getId() === next);
        heighStream && client.setRemoteVideoStreamType(heighStream, 0);

        //super.setHighStream(prev, next);
        /*
    const nextActiveEl = $("#subscribe-video-" + next);
    if (!nextActiveEl) {
      return;
    }
    if (nextActiveEl.hasClass("host-active")) {
      return;
    }
    $(".host-active")
      .addClass("active-video")
      .removeClass("host-active");
    nextActiveEl.addClass("host-active").removeClass("active-video");
    */
    }

    joinChannel(channelName, role) {
        if (role) this.viewMode = role;
        else role = this.viewMode;
        this.isJoiningChannelInProgress = true;
        return this.getChannelToken(this.accountUid, channelName, role)
            .then((tokenKey) => {
                return new Promise((resolve, reject) => {
                    if (!this.isJoiningChannelInProgress) {
                        return reject();
                    }

                    if (this.useAirmeetTURNServer && this.turnServerConfig) {
                        this.client.setTurnServer(this.turnServerConfig);
                    }
                    QOELogger(
                        broadcastLogConstants.BROADCAST_ATTEMPTING_TO_JOIN_CHANNEL,
                        this.accountUid
                    );

                    if (!tokenKey) {
                        QOELogger(
                            broadcastLogConstants.BROADCAST_ATTEMPTING_TO_JOIN_CHANNEL,
                            this.accountUid,
                            channelName
                        );
                        this.emit('token-error', {
                            error: 'TOKEN_NOT_FOUND',
                        });
                        return reject();
                    }
                    return this.client.join(
                        tokenKey,
                        channelName,
                        this.accountUid,
                        (uid) => {
                            if (!this.isJoiningChannelInProgress) {
                                this.client.leave();
                                return reject();
                            }
                            this.channelName = channelName;

                            this.isJoiningChannelInProgress = false;
                            if (this.hasEnableDualStream) {
                                this.toggleDualStreamMode(
                                    this.mode ===
                                        STAGE_STREAM_MODES.lowResolution
                                );
                                const lowStreamParam = this.isCustomMediaAccount
                                    ? [480, 360, 15, 585]
                                    : [160, 120, 15, 65];
                                this.client.setLowStreamParameter({
                                    width: lowStreamParam[0],
                                    height: lowStreamParam[1],
                                    framerate: lowStreamParam[2],
                                    bitrate: lowStreamParam[3],
                                });
                            }
                            QOELogger(
                                broadcastLogConstants.BROADCAST_CHANNEL_JOINED_SUCCESSFULLY,
                                uid
                            );
                            // Create localstream
                            resolve(uid);
                        },
                        (err) => {
                            QOELogger(
                                broadcastLogConstants.BROADCAST_FAILED_TO_JOIN_CHANNEL,
                                this.accountUid,
                                err
                            );
                            console.error('join channel error', err);
                            reject(err);
                        }
                    );
                });
            })
            .catch((err) => {
                logger.error('Error joining channel', err);
            });
    }

    addStream(stream, push = false, dId = null) {
        const streamList = this.streams;
        const self = this;
        const id = stream.getId();
        // Check for redundant
        const redundant = streamList.findIndex((item) => {
            return item.getId() === id;
        });
        // If redundant, replace the stream
        if (redundant >= 0) {
            streamList.splice(redundant, 1);
        }

        // Do push for localStream and unshift for other streams
        if (push) {
            streamList.push(stream);
        } else {
            streamList.unshift(stream);
        }
        if (!dId) {
            dId = 'subscribe-video-' + id;
        }

        this.streams = streamList;

        if (!this.mainStream) {
            this.setHighStream(null, id);
        } else if (isScreenShareStream(id) || isCustomMediaStream(id)) {
            this.setHighStream(this.mainStream.getId(), id);
        }
        // stream.play(dId);
        stream.on('player-status-change', (evt) => {
            if (evt.isErrorState && evt.status === 'paused') {
                console.error(
                    `Stream is paused unexpectedly. Trying to resume...`
                );
                stream
                    .resume()
                    .then(function () {
                        console.log(`Stream is resumed successfully`);
                    })
                    .catch(function (e) {
                        if (e.name === `NotAllowedError`) {
                            self.emit('stream-not-allowed-auto-play', { id });
                        }
                        console.error(
                            `Failed to resume stream. Error ${e.name} Reason ${e.message}`
                        );
                    });
            }
        });

        if (this.hasRemoteStreamAudioMute) {
            this.muteAudioRemoteStreams([stream]);
        }

        this.emit('stream-added', { id });
    }

    unsubscribeStream(id, removePermanently = true) {
        const streamList = this.streams;
        const { client } = this;
        /* $("#subscribe-video-" + id).length > 0 &&
       $("#subscribe-video-" + id).remove();*/
        const stream = streamList.find((item) => item.getId() === id);
        if (stream) {
            stream.close();
            QOELogger(
                broadcastLogConstants.BROADCAST_ATTEMPTING_TO_UNSUBSCRIBE,
                this.accountUid,
                `unsubscribe remote stream request: ${id}`
            );
            client.unsubscribe(stream, function (err) {});
            this.removeStream(id, removePermanently);
        }
    }

    removeStream(id, removePermanently = true, offline = false) {
        const stream = this.getStreamById(id);
        if (!stream) {
            return false;
        }
        if (stream.isPlaying()) {
            stream.stop();
        }
        /*$("#subscribe-video-" + id).length > 0 &&
      $("#subscribe-video-" + id).remove();*/
        if (isScreenShareStream(id)) {
            this.emit(Events.streamRemoveScreen, { id });
        }
        if (isCustomMediaStream(id)) {
            this.emit('remove-custom-media-stream', { id });
        }
        const mainId = this.mainStream ? this.mainStream.getId() : '';
        if (this.remoteStreams[id] && removePermanently) {
            delete this.remoteStreams[id];
        }
        super.removeStream(id, removePermanently, offline);
        if (
            (this.activeSpeaker === id || isScreenShareStream(id)) &&
            this.mainStream
        ) {
            this.activeSpeaker = this.updateActiveSpeaker(
                this.mainStream.getId()
            );
        }

        if ((mainId === id || isScreenShareStream(id)) && this.mainStream) {
            this.setHighStream(null, this.mainStream.getId());
        }

        if (
            (this.activeSpeaker === id || isCustomMediaStream(id)) &&
            this.mainStream
        ) {
            this.activeSpeaker = this.updateActiveSpeaker(
                this.mainStream.getId()
            );
        }

        if ((mainId === id || isCustomMediaStream(id)) && this.mainStream) {
            this.setHighStream(null, this.mainStream.getId());
        }
    }

    publishScreenStream(props) {
        return new Promise((resolve, reject) => {
            let { screenProfile, channelName } = props;
            const { client } = this;
            const config = {
                audio: false,
                video: false,
                screen: true,
            };
            if (!screenProfile) {
                screenProfile = this.resolutionsInfo.screenProfile;
            }
            const localStream = this.streamInit(
                this.accountUid,
                {
                    attendeeMode: 'screen',
                    screenProfile,
                },
                config
            );
            this.localStream = localStream;
            localStream.init(
                () => {
                    this.joinChannel(channelName)
                        .then(() => {
                            QOELogger(
                                broadcastLogConstants.BROADCAST_ATTEMPTING_TO_PUBLISH,
                                this.accountUid
                            );
                            this.publish();

                            localStorage.setItem(
                                `active-screen-share`,
                                this.accountUid
                            );
                            if (!this.localStream) {
                                localStorage.removeItem(`active-screen-share`);
                                logger.info(
                                    'Stop screenshare - leave channel request on publishScreenStream when local stream is not present'
                                );
                                this.leaveChannel();
                                reject('Local_Stream_Close');
                                return;
                            }
                            resolve(this.localStream);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                },
                (err) => {
                    const errorMessages = {
                        NotAllowedError: `You refused to grant access to screen share.`,
                    };
                    let message = errorMessages[err.msg]
                        ? errorMessages[err.msg]
                        : 'Please check the browser permission.';
                    if (
                        err.msg === 'NotAllowedError' &&
                        err.info === 'Permission denied'
                    ) {
                        message = `Permission to use your screen share is missing. Please allow screenshare access for an interactive experience.`;
                    }
                    localStream.close();
                    this.streamPublished = false;
                    this.localStream = null;
                    errorLog(message, err);
                    this.emit('stream-publish-fail', {
                        info: err.info,
                        type: err.msg,
                        message,
                        connectViaProxy: this.connectViaProxy,
                    });
                    reject(err);
                }
            );
        });
    }

    publish(fallbackCount = 0) {
        const { client, localStream } = this;
        let timeout;
        if (!this.localStream) {
            errorLog(
                `Fail to publish local stream fail count [${fallbackCount}]: Did not find the local stream.`
            );
            return;
        }
        if (fallbackCount === 0) {
            const isScreen = localStream.screen;
            if (isScreen) {
                this.localStream.getVideoTrack().onended = () => {
                    logger.info(
                        'Stop screenshare - stopped by user via clicking on chrome stop screenscreen button modal'
                    );
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    localStorage.removeItem(`active-screen-share`);
                    this.emit('stream-video-track-ended', {
                        id: this.localStream?.getId(),
                    });
                    this.leaveChannel();
                };
            } else {
                client.on('stream-published', (evt) => {
                    this.preventScreen = true;
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    QOELogger(
                        broadcastLogConstants.BROADCAST_PUBLISHED_STREAM_SUCCESSFULLY,
                        this.accountUid
                    );
                    localLog('Publish Stream Requested: published success');
                    if (this.localStream) {
                        this.addStream(this.localStream, true);
                    }
                });
                client.on('stream-unpublished', (evt) => {
                    if (this.localStream) {
                        this.removeStream(this.localStream.getId());
                    }
                });
            }
        }

        client.publish(localStream, (err) => {
            QOELogger(
                broadcastLogConstants.BROADCAST_FAILED_TO_PUBLISH_STREAM,
                this.accountUid,
                `${err} and stream id ${this.localStream?.getId()}`
            );
            errorLog(
                `Fail to publish local stream fail count [${fallbackCount}]: ${err}`
            );

            if (err === 'ERR_PUBLISH_REQUEST_INVALID') {
                /** 
                    This happens when stream is closed during a publish.
                    That means it have closed a local stream by other actions, So we can ignore this error.
                    */
                return;
            }
            if (timeout) {
                clearTimeout(timeout);
            }
            if (
                ['PUBLISH_STREAM_FAILED', 'PEERCONNECTION_FAILED'].includes(
                    err
                ) &&
                fallbackCount < 5
            ) {
                this.publish(fallbackCount++);
            } else if (this.localStream) {
                localStream.close();
                this.localStream = null;
                this.streamPublished = false;
                this.emit('stream-publish-fail', {
                    info: err,
                    type: 'PublishFail',
                    message: 'Unable to join due to internet connectivity.',
                    connectViaProxy: this.connectViaProxy,
                });
            }
        });

        // timeout = setTimeout(() => {
        //     if (this.localStream && !this.streamPublished) {
        //         this.enableProxyServer().catch(() => {});
        //     }
        // }, 3000);
    }

    async publishStream(props) {
        const {
            attendeeMode,
            cameraId,
            microphoneId,
            isScreen = false,
            fromFallback = false,
            elementId,
            isMedia = false,
            mirror = true,
            audioProfile = null,
        } = props;
        const { client } = this;
        const config =
            isSafari() || isMedia
                ? {}
                : await getDevicesForStream({
                      cameraId,
                      microphoneId,
                  });
        localLog('Request for publishing stream');

        let videoProfile =
            props.videoProfile ||
            (isMedia
                ? attendeeMode === 'canvas'
                    ? this.resolutionsInfo.presentationProfile
                    : this.resolutionsInfo.customMediaProfile
                : this.resolutionsInfo.videoProfile);

        if (isMedia) {
            // NOTE:
            // Whether a media element is actively rendering content (e.g., to a screen or audio device) has no effect on the content of captured streams.
            // Muting the audio on a media element does not cause the capture to produce silence,
            // nor does hiding a media element cause captured video to stop.
            // Similarly, the audio level or volume of the media element does not affect the volume of captured audio

            const mediaElement = document.getElementById(elementId);
            if (attendeeMode === 'canvas') {
                mediaElement.getContext('2d');
            }
            const mediaStream = mediaElement.captureStream(60);

            // capture stream adds a new video tag to the DOM and removes it on closing the stream
            // which is not visible but has an active audio source and creates an echo effect on the host's browser.
            // so we need to find that video element from the DOM and mute it.
            // it is safe to assume that the last element in the document.getElementsByTagName('video') array
            // setting it inside a timeout as captureStream takes about a second to initialize the new video tag.
            // this works fine on firefox so no action is needed
            if (isChrome() && mediaElement?.tagName === 'VIDEO') {
                setTimeout(() => {
                    const videoElems = document.getElementsByTagName('video');

                    for (let i = 0, len = videoElems.length; i < len; i++) {
                        if (
                            videoElems[i] &&
                            !/video|custom-video-player/i.test(videoElems[i].id)
                        ) {
                            logger.info(
                                `muted the video element created by captureStream API for custom media mode: ${attendeeMode}`
                            );
                            videoElems[i].muted = true;
                        }
                    }
                }, 2000);
            }
            if (attendeeMode !== 'canvas') {
                [config.audioSource] = mediaStream.getAudioTracks();
                config.audio = !!config.audioSource;
            } else {
                config.audio = false;
                config.optimizationMode = 'detail';
            }
            [config.videoSource] = mediaStream.getVideoTracks();
            config.video = !!config.videoSource;
        }

        const localStream = this.streamInit(
            this.accountUid,
            {
                attendeeMode,
                videoProfile: videoProfile,
                audioProfile: audioProfile,
                screenProfile: isScreen ? videoProfile : null,
            },
            { ...config, mirror }
        );
        this.localStream = localStream;

        streamInitPromise(localStream).then((error) => {
            const successCallback = async () => {
                localLog('Publish Stream Requested: init success');
                QOELogger(
                    broadcastLogConstants.BROADCAST_ATTEMPTING_TO_PUBLISH,
                    this.accountUid
                );

                if (
                    localStream &&
                    !fromFallback &&
                    ((this.localStream.hasVideo() &&
                        !this.localStream.getVideoTrack()) ||
                        (this.localStream.hasAudio() &&
                            !this.localStream.getAudioTrack()))
                ) {
                    localStream.close();
                    this.streamPublished = false;
                    this.localStream = null;
                    QOELogger(
                        broadcastLogConstants.BROADCAST_FAILED_TO_GET_VIDEO_STREAM,
                        this.accountUid
                    );
                    return this.publishStream({
                        ...props,
                        attendeeMode: 'audio-only',
                        fromFallback: true,
                    });
                } else if (
                    localStream &&
                    !localStream.getAudioTrack() &&
                    !this.localStream.getVideoTrack()
                ) {
                    localStream.close();
                    this.streamPublished = false;
                    this.localStream = null;
                    QOELogger(
                        broadcastLogConstants.BROADCAST_FAILED_TO_GET_TRACK_IN_STREAM,
                        this.accountUid
                    );
                    const err = {
                        info:
                            broadcastLogConstants.BROADCAST_FAILED_TO_GET_TRACK_IN_STREAM,
                        type: 'NO_TRACK_FOUND',
                        message:
                            'Unable to access your camera or microphone. Please give permissions and try again.',
                        connectViaProxy: this.connectViaProxy,
                    };
                    this.emit('stream-publish-fail', err);
                    props.onInitStreamSuccessFail &&
                        props.onInitStreamSuccessFail(err);
                    return;
                }
                if (isMedia && !isScreen)
                    errorLog('Publishing pre-recorded video stream');

                if (
                    attendeeMode === 'canvas' &&
                    config.video &&
                    this.localStream.getVideoTrack()
                ) {
                    this.localStream.getVideoTrack().contentHint = 'text';
                } else if (
                    !isMedia &&
                    this._canAllowToUseVideoTrack() &&
                    this.localStream.getVideoTrack()
                ) {
                    this.publishVideoProfile = videoProfile;
                    await this._startLocalVideo(videoProfile);
                }
                this.setVideoEncoderProfile(attendeeMode);

                this.publish();

                if (
                    this.localStream &&
                    this.localStream.hasVideo() &&
                    this.localStream.isVideoOn() &&
                    !isMedia &&
                    !this._canAllowToUseVideoTrack()
                ) {
                    // if video is on, call applyFilter method.
                    // Whether filter will be applied or not, applyFilter fn will handle it.
                    this.applyFilter();
                }

                props.onInitStreamSuccess &&
                    props.onInitStreamSuccess(localStream);
            };

            const errorCallback = (err) => {
                const errorMessages = {
                    NotAllowedError: `You refused to grant access to camera or audio resource.`,
                    MEDIA_OPTION_INVALID: `The camera is occupied or the resolution is not supported.`,
                    DEVICES_NOT_FOUND: `No device is found.`,
                    NOT_SUPPORTED: `The browser does not support using camera and microphone.`,
                    PERMISSION_DENIED: `The device is disabled by the browser or the user has denied permission of using the device.`,
                    CONSTRAINT_NOT_SATISFIED: `The settings are illegal(on browsers in early versions).`,
                    AbortError: `Starting video failed.`,
                };
                let message = errorMessages[err.msg]
                    ? errorMessages[err.msg]
                    : 'Please check the browser permission.';
                if (err.msg === 'NotAllowedError') {
                    message = `Unable to access your camera or microphone. Please give permissions and try again.`;
                }
                localStream.close();
                this.streamPublished = false;
                this.localStream = null;
                logger.info(
                    'RTC Stream publish error',
                    message,
                    err,
                    !fromFallback
                );
                if (
                    !fromFallback &&
                    !isScreen &&
                    !['STREAM_IS_CLOSED', 'AbortError'].includes(err.msg)
                ) {
                    logger.info(
                        'RTC Stream try with audio only mode after init fail'
                    );
                    return this.publishStream({
                        ...props,
                        attendeeMode: 'audio-only',
                        fromFallback: true,
                    });
                } else {
                    if (isScreen) {
                        logger.info(
                            'Stop screenshare - leave channel request due to failed screenshare[stream-publish-fail]'
                        );
                        this.leaveChannel();
                    }
                    this.emit('stream-publish-fail', {
                        info: err.info,
                        type: err.msg,
                        message,
                        connectViaProxy: this.connectViaProxy,
                    });
                    props.onInitStreamSuccessFail &&
                        props.onInitStreamSuccessFail(err);
                }
            };

            return error ? errorCallback(error) : successCallback();
        });
    }

    async applyFilter(stream) {
        const filterStorage = FilterStorageManager.getInstance();
        if (!stream) {
            stream = this.localStream;
        }
        if (
            !this.canUseFilter ||
            !this.filterManager ||
            !this.isFilterSupported ||
            !stream
        )
            return;

        let filterInfo = null;
        try {
            filterInfo = filterStorage.getParsedFilterInfo();
            this.filterInfo = filterInfo;
        } catch (err) {
            stageFilterLogger.error(
                'Error while parsing filter information from localStorage in boardcastClient',
                err
            );
        }

        if (!filterInfo || !filterInfo.type || filterInfo.type === 'none') {
            if (this.filterManager.isFilterActive()) {
                await this.stopFilter();
            }
            stageFilterLogger.debug(
                `Attempt to apply filter but filter information not found in localstorage. FilterInfo: ${JSON.stringify(
                    filterInfo
                )}`
            );
            return;
        }

        try {
            await this.filterManager.applyFilter(
                stream,
                filterInfo.type,
                filterInfo.details || {}
            );
            stageFilterLogger.info(filterLogs.applySuccess);
        } catch (err) {
            stageFilterLogger.error(filterLogs.applyFail, err);
        }
    }

    async stopFilter() {
        if (!this.filterManager || !this.isFilterSupported || !this.localStream)
            return;

        if (!this.filterManager.isFilterActive()) {
            stageFilterLogger.debug(
                'Attempt to stop filter but filter is not running'
            );
            return;
        }

        try {
            await this.filterManager.stopFilter(this.localStream);
            stageFilterLogger.info(filterLogs.removeSuccess);
        } catch (err) {
            stageFilterLogger.error(filterLogs.removeFail, err);
        }
    }

    async toggleFilter() {
        if (!this.localStream) {
            return;
        }
        const isVideoOn =
            this.localStream.hasVideo() && this.localStream.isVideoOn();
        return await (isVideoOn ? this.applyFilter() : this.stopFilter());
    }

    setVideoEncoderProfile(attendeeMode) {
        if (!this.localStream) return;
        const videoTrack = this.localStream.getVideoTrack();
        if (!videoTrack) return;

        const localStream = this.localStream;
        let streamConfig = {};
        if (isCustomMediaStream(this.localStream.getId())) {
            streamConfig = this.resolutionsInfo.customMediaConfig;
        } else {
            return;
        }

        // Set the video encoder config in that case share the pdf/canvas
        const settings = videoTrack.getSettings();
        const streamHeight =
            settings.height < streamConfig.height
                ? settings.height
                : streamConfig.height;

        // Safari doesn't provides aspect ratio value
        settings.aspectRatio =
            settings.aspectRatio || settings.width / settings.height;

        QOELogger(
            broadcastLogConstants.BROADCAST_MEDIA_STREAM_CONFIG,
            this.accountUid,
            JSON.stringify(streamConfig)
        );

        localStream.setVideoEncoderConfiguration({
            // Video resolution
            resolution: {
                width: parseInt(settings.aspectRatio * streamHeight),
                height: streamHeight,
            },
            // Video encoding frame rate. We recommend 15 fps. Do not set this to a value greater than 30.
            frameRate: {
                min: streamConfig.minFrameRate,
                max: streamConfig.maxFrameRate,
            },
            // Video encoding bitrate.
            bitrate: {
                min: streamConfig.minBitRate,
                max: streamConfig.maxBitRate,
            },
        });
    }

    reset() {
        this.channelName = null;
        this.localStream = null;
        this.streams = [];
        this.remoteMuteVideos = {};
        this.remoteMuteAudios = {};
        this.isJoiningChannelInProgress = false;
        this.deleteAllTimer();
        window.removeEventListener('error', this.sdpErrorHandle);
        this.hasRemoteStreamAudioMute = false;
    }

    unpublishStream(preventScreen) {
        return new Promise((resolve, reject) => {
            const { localStream, client, channelName, accountUid } = this;
            if (!channelName || !client || !localStream) {
                return resolve(true);
            }

            if (localStorage.getItem('active-screen-share') && !preventScreen) {
                localStorage.removeItem('active-screen-share');
            }
            localLog(
                `Channel Leave request received, unpublishStream: ${channelName} ${accountUid}`
            );

            this.preventScreen = preventScreen;
            QOELogger(
                broadcastLogConstants.BROADCAST_ATTEMPTING_TO_UNPUBLISH,
                this.accountUid
            );
            client.unpublish(localStream);
            if (this.canUseFilter && this.filterManager) {
                this.closeFilterManager();
            }
            this.removeStream(localStream.getId());
            localStream.close();
            if (this.localVideoStream) {
                this.localVideoStream.close();
            }
            this.stopLocalVolumeCheck();
            this.streamPublished = false;
            this.localStream = null;
            this.localVideoStream = null;
            resolve(true);
        });
    }

    leaveChannel(n) {
        return new Promise((resolve, reject) => {
            const { client, channelName, accountUid } = this;
            localLog(
                `Channel Leave request received: ${channelName} ${accountUid}`
            );
            if (this.canUseFilter) {
                this.closeFilterManager();
            }
            if (!channelName || !client) {
                this.reset();
                return resolve();
            }
            this.unpublishStream();
            this.stopLocalVolumeCheck();
            client.leave(
                () => {
                    this.reset();
                    resolve();
                },
                (err) => {
                    localLog(
                        `Channel Leave request failed: ${channelName}`,
                        err
                    );
                    this.reset();
                    resolve();
                }
            );
        });
    }

    infoDetectSchedule(durationss = 10) {
        if (!['test', 'dev'].includes(process.env.REACT_APP_ENV)) {
            return;
        }
        const durations = 10;
        setInterval(() => {
            const streamList = this.getStreams();
            const { localStream } = this;
            let no = streamList.length;
            const statsLogs = {};
            for (let i = 0; i < no; i++) {
                let item = streamList[i];
                const streamLogs = [];
                const id = item.getId();
                item.getStats((stats) => {
                    streamLogs.push(`Stream Id: ${id}`);
                    if (localStream && id === localStream.getId()) {
                        streamLogs.push(
                            `Local Stream accessDelay: ${stats.accessDelay}`
                        );
                        streamLogs.push(
                            `Local Stream audioSendBytes: ${stats.audioSendBytes}`
                        );
                        streamLogs.push(
                            `Local Stream audioSendPackets: ${stats.audioSendPackets}`
                        );
                        streamLogs.push(
                            `Local Stream audioSendPacketsLost: ${stats.audioSendPacketsLost}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendBytes: ${stats.videoSendBytes}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendFrameRate: ${stats.videoSendFrameRate}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendPackets: ${stats.videoSendPackets}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendPacketsLost: ${stats.videoSendPacketsLost}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendResolutionHeight: ${stats.videoSendResolutionHeight}`
                        );
                        streamLogs.push(
                            `Local Stream videoSendResolutionWidth: ${stats.videoSendResolutionWidth}`
                        );
                    } else {
                        const videoBytes = stats.videoReceiveBytes;
                        const audioBytes = stats.audioReceiveBytes;
                        const videoPackets = stats.videoReceivePackets;
                        const audioPackets = stats.audioReceivePackets;
                        const videoPacketsLost = stats.videoReceivePacketsLost;
                        const audioPacketsLost = stats.audioReceivePacketsLost;
                        streamLogs.push(
                            `Remote Stream accessDelay: ${stats.accessDelay}`
                        );
                        streamLogs.push(
                            `Remote Stream audioReceiveBytes: ${stats.audioReceiveBytes}`
                        );
                        streamLogs.push(
                            `Remote Stream audioReceiveDelay: ${stats.audioReceiveDelay}`
                        );
                        streamLogs.push(
                            `Remote Stream audioReceivePackets: ${stats.audioReceivePackets}`
                        );
                        streamLogs.push(
                            `Remote Stream audioReceivePacketsLost: ${stats.audioReceivePacketsLost}`
                        );
                        streamLogs.push(
                            `Remote Stream endToEndDelay: ${stats.endToEndDelay}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveBytes: ${stats.videoReceiveBytes}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveDecodeFrameRate: ${stats.videoReceiveDecodeFrameRate}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveDelay: ${stats.videoReceiveDelay}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveFrameRate: ${stats.videoReceiveFrameRate}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceivePackets: ${stats.videoReceivePackets}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceivePacketsLost: ${stats.videoReceivePacketsLost}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveResolutionHeight: ${stats.videoReceiveResolutionHeight}`
                        );
                        streamLogs.push(
                            `Remote Stream videoReceiveResolutionWidth: ${stats.videoReceiveResolutionWidth}`
                        );

                        // Do calculate
                        const videoBitrate =
                            (videoBytes / 1000 / durations).toFixed(2) + 'KB/s';
                        const audioBitrate =
                            (audioBytes / 1000 / durations).toFixed(2) + 'KB/s';
                        let vPacketLoss =
                            ((videoPacketsLost / videoPackets) * 100).toFixed(
                                2
                            ) + '%';
                        let aPacketLoss =
                            ((audioPacketsLost / audioPackets) * 100).toFixed(
                                2
                            ) + '%';
                        let sumPacketLoss = (
                            (videoPacketsLost / videoPackets) * 100 +
                            (audioPacketsLost / audioPackets) * 100
                        ).toFixed(2);

                        streamLogs.push(
                            `Video Bitrate: ${videoBitrate} Packet Loss: ${vPacketLoss}`
                        );

                        streamLogs.push(
                            `Audio Bitrate: ${audioBitrate} Packet Loss: ${aPacketLoss}`
                        );
                        streamLogs.push(`Packet Lost: ${sumPacketLoss}`);
                        let qualityHtml;
                        if (sumPacketLoss < 1) {
                            qualityHtml = 'Excellent';
                        } else if (sumPacketLoss < 5) {
                            qualityHtml = 'Good';
                        } else if (sumPacketLoss < 10) {
                            qualityHtml = 'Poor';
                        } else if (sumPacketLoss < 100) {
                            qualityHtml = 'Bad';
                        } else {
                            qualityHtml = 'Get media failed.';
                        }
                        streamLogs.push(`Quality: ${qualityHtml}`);
                    }

                    statsLogs[id] = streamLogs;
                });
            }

            EventBridge.notify('BroadcastClient', 'Streams info', statsLogs);
        }, durations * 1000);
    }

    checkForFirewall(props = {}) {
        return new Promise((resolve, reject) => {
            const silentLeave = () => {
                try {
                    this.client.leave();
                } catch (e) {
                    // ignore the error
                    console.error(`
                        error leaving channel in firewall test: ${JSON.stringify(
                            e
                        )}
                    `);
                }
            };

            const timeoutDuration = props.delay * 1000 || 10000;
            const timeout = setTimeout(() => {
                if (!this.channelName) {
                    silentLeave();
                    reject({
                        code: 'TIMEOUT_TO_JOIN_CHANNEL',
                        message: "couldn't join web rtc channel. timing out",
                    });
                } else {
                    this.client.leave();
                    resolve();
                }
            }, timeoutDuration);
            const channelName = uuidv4();
            this.joinChannel(channelName)
                .then(() => {
                    timeout && clearTimeout(timeout);
                    if (!props.publish) {
                        silentLeave();
                        return resolve();
                    }

                    const onReject = (error) => {
                        QOELogger(
                            broadcastLogConstants.BROADCAST_FAILED_TO_PUBLISH_STREAM,
                            this.accountUid,
                            `${JSON.stringify(
                                error
                            )} and stream id ${this.localStream?.getId()}`
                        );
                        this.localStream && this.localStream.close();
                        this.client.leave();
                        publishTimeout && clearTimeout(publishTimeout);
                        return reject(error);
                    };

                    const publishTimeout = setTimeout(() => {
                        onReject({
                            code: 'TIMEOUT_TO_PUBLISH',
                            message: "couldn't publish stream. timing out",
                        });
                    }, timeoutDuration);

                    const onSucess = () => {
                        this.stopLocalVolumeCheck();
                        if (this.localStream) {
                            this.client.unpublish(this.localStream);
                            this.localStream.close();
                        }

                        silentLeave();
                        publishTimeout && clearTimeout(publishTimeout);
                        resolve();
                    };

                    this.client.on('stream-published', (evt) => {
                        onSucess();
                    });
                    this.on('stream-publish-fail', (event) => {
                        return onReject({
                            code: 'FAIL_TO_PUBLISH',
                            message: "couldn't publish stream",
                            event,
                        });
                    });

                    this.publishStream(props.publish);
                })
                .catch((error) => {
                    silentLeave();
                    timeout && clearTimeout(timeout);
                    reject(error);
                });
        });
    }

    setWatchMode(mode) {
        if (!mode) {
            return;
        }
        this.mode = mode;

        this.getStreams().forEach((stream) => {
            if (!isScreenShareStream(stream.getId()) && !stream.local) {
                this.subscribeStream(stream);
                const lowResolution =
                    this.mode === STAGE_STREAM_MODES.lowResolution ? 1 : 0;
                this.client.setRemoteVideoStreamType(stream, lowResolution);

                this.handleLocalStreamVideoProfile();
            }
        });
    }

    _canAllowToUseVideoTrack() {
        return this.cameraOffOnMute && isChromeBrowser();
    }

    _startLocalVideo() {
        const videoProfile =
            this.publishVideoProfile ||
            this.resolutionsInfo?.videoProfile ||
            '360p_1';
        if (this.localStream) {
            return new Promise(async (resolve, reject) => {
                const { cameraId } = await getDevicesForStream();
                const config = isSafari()
                    ? {}
                    : {
                          cameraId,
                      };
                try {
                    const oldLocalVideoStream = this.localVideoStream;
                    this.localVideoStream = this.streamInit(
                        this.uid,
                        { attendeeMode: 'video-only', videoProfile },
                        config
                    );
                    if (oldLocalVideoStream) {
                        this.localVideoStream.close();
                    }
                    // initialize the temp video stream (this is where getUserMedia is called under the hood)
                    this.localVideoStream.init(async () => {
                        try {
                            await this.applyFilter(this.localVideoStream);
                            // add the video track from the freshly initialized temp video stream to the local stream
                            if (this.localStream.hasVideo()) {
                                await this.localStream.replaceTrack(
                                    this.localVideoStream.getVideoTrack()
                                );
                            } else {
                                await this.localStream.addTrack(
                                    this.localVideoStream.getVideoTrack()
                                );
                            }
                            this.localStream.muteVideo();
                            this.localStream.unmuteVideo();
                            // Add delay to publish video track
                            await new Promise((resolve) => {
                                setTimeout(resolve, 2000);
                            });
                            resolve();
                        } catch (e) {
                            errorLog(e);
                            reject();
                        }
                    }, reject);
                } catch (e) {
                    errorLog(e);
                    reject(e);
                }
            });
        } else {
            errorLog('Stream is not initialized');
            Promise.reject();
        }
    }

    _stopLocalVideo() {
        return new Promise((resolve, reject) => {
            const { localStream, localVideoStream } = this;
            if (localStream && localVideoStream) {
                try {
                    // mute video so remote participants get notification, then immediately unmute
                    localStream.muteVideo();
                    // close the temp video stream, then remove track and stop local stream (order is important)
                    if (localVideoStream !== null) localVideoStream.close();
                    this.localVideoStream = null;
                    localStream.removeTrack(localStream.getVideoTrack());
                    return resolve();
                } catch (e) {
                    errorLog(e);
                    return reject();
                }
            } else if (localStream) {
                return resolve();
            } else {
                errorLog('Stream is not initialized');
                return reject();
            }
        });
    }

    muteLocalVideo() {
        return new Promise((resolve, reject) => {
            if (this._canAllowToUseVideoTrack()) {
                return this._stopLocalVideo().then(resolve).catch(reject);
            } else if (this.localStream?.hasVideo()) {
                return this.localStream.muteVideo() ? resolve() : reject();
            }
            return reject();
        });
    }

    async unmuteLocalVideo() {
        return new Promise((resolve, reject) => {
            if (this._canAllowToUseVideoTrack()) {
                return this._startLocalVideo().then(resolve).catch(reject);
            } else if (this.localStream?.hasVideo()) {
                // for Firefox and Safari
                return this.localStream.unmuteVideo() ? resolve() : reject();
            }
            return reject();
        });
    }

    handleLocalStreamVideoProfile() {
        if (!this.localStream) {
            return;
        }
        const { layout, noOfStreams, mainStreamUids } = this
            .videoProfileParams || {
            layout: null,
            noOfStreams: 0,
        };

        traceLog('handleLocalStreamVideoProfile', {
            layout,
            noOfStreams,
            mainStreamUids,
        });
        this.updatePublishVideoProfile(layout, noOfStreams, mainStreamUids);
    }

    async onVideoModeToggle() {
        if (!this.localStream) {
            return;
        }
        await this.toggleFilter();
        this.handleLocalStreamVideoProfile();
    }

    setCameraOffOnMuteFlag(enable) {
        this.cameraOffOnMute = enable;
        traceLog('Set the camera off on mute flag', {
            cameraOffOnMute: this.cameraOffOnMute,
        });
        this.onVideoModeToggle();
    }

    toggleDualStreamMode(disableDualStream) {
        if (this.hasEnableDualStream) {
            traceLog('ToggleDualStreamMode', {
                isDualStreamEnabled: this.client.isDualStream,
                disableDualStream,
            });
            if (disableDualStream) {
                this.client.isDualStream &&
                    this.client.disableDualStream(
                        function () {
                            traceLog('Disable dual stream success!');
                        },
                        function (err) {
                            traceLog('Disable dual stream failed!', err);
                        }
                    );
            } else {
                !this.client.isDualStream &&
                    this.client.enableDualStream(
                        function () {
                            traceLog('Enable dual stream success!');
                        },
                        function (err) {
                            traceLog('Enable dual stream failed!', err);
                        }
                    );
            }
        }
    }

    async setStreamAudioOutput(streams = []) {
        const { speakerId: speakerDeviceId } = await getDevicesForStream();
        streams = streams.length > 0 ? streams : this.getStreams();
        streams.forEach((stream) => {
            stream.setAudioOutput(speakerDeviceId, () =>
                logger.info(
                    `set the audio output for stream [${stream.getId()}]:${speakerDeviceId}`
                )
            );
        });
    }

    /**
     *
     * @param {*} mute
     */
    setRemoteStreamAudio = (mute) => {
        logger.info('setRemoteStreamAudio', mute);
        if (mute === this.hasRemoteStreamAudioMute) {
            return;
        }

        if (mute) {
            this.muteAudioRemoteStreams(this.streams);
        } else {
            this.unmuteAudioRemoteStreams(this.streams);
        }

        this.hasRemoteStreamAudioMute = mute;
        this.emit('remote-streams-audio-mode-update', { mute });
    };

    muteAudioRemoteStreams = (streams = []) => {
        streams.forEach((stream) => {
            if (stream.local) {
                return;
            }

            logger.info('muteAudioRemoteStreams muted stream', stream.getId());

            stream.muteAudio();
        });
    };

    unmuteAudioRemoteStreams = (streams = []) => {
        streams.forEach((stream) => {
            if (stream.local) {
                return;
            }

            logger.info(
                'unmuteAudioRemoteStreams unmuted stream',
                stream.getId()
            );

            stream.unmuteAudio();
        });
    };
}
